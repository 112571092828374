import { Banner, Link, Modal, Text } from "@shopify/polaris";
import { adminRoutePrefix } from "@smartrr/shared/constants";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import React from "react";

import { navigateToSubscriptionDetails } from "@vendor-app/utils/navigateWithShopInQuery";

interface IFailedPurchaseStateInfo {
  subscription: IPurchaseStateWithCustomerRelationship;
  reason: string;
}

export type FailedPurchaseStateAction = "pause" | "cancel" | "unpause" | "set next date for" | undefined;

export interface IFailedSubscriptionWithReason {
  reason: string;
  subscription: IPurchaseStateWithCustomerRelationship;
}

interface IErrorModalProps {
  open: boolean;
  errorAction: FailedPurchaseStateAction;
  failedPurchaseStates: IFailedPurchaseStateInfo[];
  closeModal: () => void;
}

export const SubscriptionErrorModal = ({
  open,
  errorAction,
  failedPurchaseStates,
  closeModal,
}: IErrorModalProps) => {
  const openFailedCPSInNewTab = (failedPurchaseStates: IFailedPurchaseStateInfo[] | undefined) => {
    if (failedPurchaseStates?.length && failedPurchaseStates.length <= 10) {
      for (const { subscription } of failedPurchaseStates) {
        if (subscription.shopifyId && subscription.custRel?.shopifyId) {
          navigateToSubscriptionDetails(subscription.shopifyId, true);
        }
      }
    }
  };

  return (
    <Modal
      sectioned
      open={open}
      onClose={closeModal}
      title="Error"
      primaryAction={{
        content: "Open all in new tabs",
        onAction: () => openFailedCPSInNewTab(failedPurchaseStates),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: closeModal,
        },
      ]}
    >
      <Banner status="critical">
        <Text as="p" variant="bodyMd">
          We were unable to {errorAction} the following subscriptions due to errors within their subscription.
          Please resolve the errors and try again.
        </Text>
        <br />
        {failedPurchaseStates.map((failedSub, index) => {
          const customer = failedSub.subscription.custRel;
          const { subscription } = failedSub;

          if (!subscription?.shopifyId || !customer?.shopifyId) {
            return (
              <Text key={`failed-subscription-text-${index}`} as="p" variant="bodyMd" breakWord>
                {`${customer?.firstName} ${customer?.lastName} - #${
                  subscription?.shopifyId ? shopifyGidToNumber(subscription?.shopifyId) : subscription.id
                }: ${failedSub.reason}`}
              </Text>
            );
          }

          return (
            <Link
              id={`failed-subscription-link-${index}`}
              key={`failed-subscription-link-${index}`}
              external
              monochrome={false}
              url={`${adminRoutePrefix}/subscriptions/${shopifyGidToNumber(subscription.shopifyId)}`}
            >
              <Text as="p" variant="bodyMd" breakWord>
                {`${customer?.firstName} ${customer?.lastName} - #${shopifyGidToNumber(
                  subscription?.shopifyId
                )}: ${failedSub.reason}`}
              </Text>
            </Link>
          );
        })}
      </Banner>
    </Modal>
  );
};
